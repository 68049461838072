<template>
  <div :style="backCss">
    <a-spin :spinning="tableLoading">
      <a-dropdown v-model="rightVisible" :placement="'bottomRight'" :trigger="['contextmenu']">
        <a-menu slot="overlay">
          <a-menu-item v-for="(item, index) in columns" :key="item.dataIndex">
            <a-checkbox v-model="item.checked" @change="checkChange(index)">
              {{ item.title }}
            </a-checkbox>
          </a-menu-item>
        </a-menu>
        <vxe-table
          border="none"
          ref="xTree"
          :row-config="{ isCurrent: true, isHover: true, keyField: 'id' }"
          :column-config="{ resizable: true }"
          @checkbox-change="selectChangeEvent"
          @checkbox-all="selectChangeEvent"
          @cell-click="cellClickEvent"
          @toggle-tree-expand="toggleExpandChangeEvent"
          @filter-visible="filterVisible"
          :tree-config="treeConfig"
          :filter-config="{
            remote: true,
            iconNone: 'iconfont icon-shaixuan icon',
            iconMatch: 'iconfont icon-shaixuan icon filter-product-btn'
          }"
          :expand-config="{ reserve: true }"
          @filter-change="filterChangeEvent"
          :data="tableData"
        >
          <vxe-column align="center" fixed="left" type="checkbox" minWidth="50" show-overflow> </vxe-column>

          <vxe-column
            v-for="record in copyColumns"
            :key="record.dataIndex"
            :field="record.dataIndex"
            :title="record.title"
            :minWidth="record.width"
            :tree-node="record.dataIndex == 'name'"
            show-overflow
            :filters="record.dataIndex == 'status' || record.dataIndex == 'contractType' ? [] : null"
          >
            <template v-if="record.dataIndex == 'status'" v-slot="{ row }">
              <div v-if="row.isDepart && row.productTotal > 50" style="width:200px">
                <a-pagination
                  size="small"
                  :showLessItems="true"
                  :defaultPageSize="50"
                  :default-current="1"
                  :total="row.productTotal - 0"
                  @change="
                    (page, pageSize) => {
                      treeTablePageChange(page, pageSize, row)
                    }
                  "
                />
              </div>
              <div v-else>
                {{ row.status | statusFilter }}
              </div>
            </template>
            <template v-else-if="record.dataIndex == 'name'" v-slot="{ row }">
              <span>{{ row.name }}{{ row.isDepart && row.productTotal ? '（' + row.productTotal + '）' : '' }}</span>
            </template>
            <template v-else-if="record.dataIndex == 'level'" v-slot="{ row }">
              <a-select @click.stop v-if="isEdit && !row.hasChild" v-model="row.level">
                <a-select-option value="专家">专家</a-select-option>
                <a-select-option value="高级">高级</a-select-option>
                <a-select-option value="中级">中级</a-select-option>
                <a-select-option value="低级">低级</a-select-option>
                <a-select-option value="新人">新人</a-select-option>
              </a-select>
              <span v-else>{{ row.ratingOpened || userInfo.id != row.userId ? row.level : '' }}</span>
              <!-- <span v-else>{{ row.level }}</span> -->
            </template>
            <template v-else-if="record.dataIndex == 'duration'" v-slot="{ row }">
              <span>{{
                row.ratingOpened || userInfo.id != row.userId
                  ? row.duration != null
                    ? row.duration > 0
                      ? row.duration + '个月'
                      : '小于1个月'
                    : ''
                  : ''
              }}</span>
              <!-- <span>{{ row.duration != null ? (row.duration > 0 ? row.duration + '个月' : '小于1个月') : '' }}</span> -->
            </template>
            <template v-else-if="record.dataIndex == 'taskCount'" v-slot="{ row }">
              <span>{{ row.ratingOpened || userInfo.id != row.userId ? row.taskCount : '' }}</span>
              <!-- <span>{{ row.taskCount }}</span> -->
            </template>
            <template v-else-if="record.dataIndex == 'action'" v-slot="{ row }">
              <a v-if="!row.hasChild" @click.stop="showTaskList(row)">查看明细</a>
            </template>
            <template v-else-if="record.dataIndex == 'averageScore'" v-slot="{ row }">
              <span>{{ row.ratingOpened || userInfo.id != row.userId ? row.averageScore : '' }}</span>
              <!-- <span>{{ row.averageScore }}</span> -->
            </template>
            <template v-if="record.dataIndex == 'status'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:20px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedStatusKeys"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
            <template v-else-if="record.dataIndex == 'contractType'" #filter="{ $panel, column }">
              <a-spin
                :spinning="filterLoading"
                style="padding-left:10px;padding-right:20px;min-height:30px;max-height:200px;overflow-y:auto"
              >
                <a-tree
                  v-model="checkedContractTypeKeys"
                  @select="
                    (selectedKeys, info) => {
                      treeSelect(record.dataIndex, selectedKeys, info)
                    }
                  "
                  checkable
                  :tree-data="treeData"
                />
              </a-spin>
            </template>
          </vxe-column>
        </vxe-table>
      </a-dropdown>
    </a-spin>
    <show-announcement :getTableData="getTableData" ref="ShowAnnouncement"></show-announcement>
  </div>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
import ShowAnnouncement from '@/components/tools/ShowAnnouncement'
import { simpleDebounce } from '@/utils/util'
import moment from 'moment'
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    queryParamProp: {
      type: Object,
      default: () => {}
    },
    productionName: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ShowAnnouncement
  },
  data() {
    return {
      visible: false,
      columns: [
        {
          title: '分组',
          dataIndex: 'team',
          width: 300,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '签约类型',
          dataIndex: 'contractType',
          checked: true,
          width: 150,
          isNum: false,
          checked: true
        },
        {
          title: '职位',
          dataIndex: 'positionName',
          checked: true,
          width: 100,
          isNum: false,
          checked: true
        },
        {
          title: '人员',
          dataIndex: 'nickName',
          checked: true,
          width: 150,
          isNum: false,
          checked: true
        },
        {
          title: '合作时长',
          dataIndex: 'duration',
          checked: true,
          width: 100,
          isNum: true,
          checked: true
        },
        {
          title: '任务数量',
          dataIndex: 'taskCount',
          checked: true,
          width: 100,
          isNum: true,
          checked: true
        },
        {
          title: '平均分',
          dataIndex: 'averageScore',
          checked: true,
          width: 150,
          isNum: true,
          checked: true
        },
        {
          title: '等级',
          scopedSlots: { customRender: 'level' },
          dataIndex: 'level',
          width: 150,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
          dataIndex: 'status',
          width: 200,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          dataIndex: 'action',
          width: 200,
          checked: true,
          isNum: false,
          checked: true
        }
      ],
      treeConfig: {
        lazy: true,
        hasChild: 'hasChild',
        rowField: 'id',
        loadMethod: this.loadChildrenMethod
      },
      checkedStatusKeys: [],
      userName: '',
      filterLoading: false,
      treeData: [],
      copyColumns: [
        {
          title: '分组',
          dataIndex: 'name',
          width: 300,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '签约类型',
          dataIndex: 'contractType',
          checked: true,
          width: 150,
          isNum: false,
          checked: true
        },
        {
          title: '职位',
          dataIndex: 'positionName',
          checked: true,
          width: 100,
          isNum: false,
          checked: true
        },
        {
          title: '人员',
          dataIndex: 'nickName',
          width: 150,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '合作时长',
          dataIndex: 'duration',
          width: 150,
          checked: true,
          isNum: true,
          checked: true
        },
        {
          title: '任务数量',
          dataIndex: 'taskCount',
          width: 100,
          checked: true,
          isNum: true,
          checked: true
        },
        {
          title: '平均分',
          dataIndex: 'averageScore',
          checked: true,
          width: 100,
          isNum: true,
          checked: true
        },
        {
          title: '等级',
          scopedSlots: { customRender: 'level' },
          dataIndex: 'level',
          width: 150,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
          dataIndex: 'status',
          width: 200,
          checked: true,
          isNum: false,
          checked: true
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          dataIndex: 'action',
          width: 200,
          checked: true,
          isNum: false,
          checked: true
        }
      ],
      tableData: [],
      methodName: 'totalScore',
      taskList: [],
      propertys: [],
      selectRows: [],
      queryParmas: {
        current: 1,
        size: 50,
        groupBy: 'month',
        orderBy: 'contractType:asc'
      },
      color: '',
      productName: '',
      rightVisible: false,
      filters: [],
      gexpr: '0',
      isUserSelect: false,
      tableLoading: false,
      selectedTreeKeys: [],
      minTotalScore: null,
      maxTotalScore: null,
      nodeList: [],
      nodeListFixed: [],
      scrollList: [],
      moveNode: null,
      checkedContractTypeKeys: [],
      moveNodeFixed: null,
      statusMap: {
        BOSS_CONFIRM: 'HR已确认',
        HR_CONFIRM: '自动推送',
        STAFF_CONFIRM: 'BOSS已确认',
        FINAL_CONFIRM: '人员已确认',
        UNKNOWN: '未知'
      },
      height: 'calc(100vh - 305px)'
    }
  },
  created() {
    this.getTableData()
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
    this.$nextTick(() => {
      Array.from(document.getElementsByClassName('vxe-filter--btn')).forEach((item, index) => {
        item.className = item.className + ' filter-' + this.copyColumns[index].dataIndex + '-btn'
      })
    })
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        BOSS_CONFIRM: 'HR已确认',
        HR_CONFIRM: '自动推送',
        STAFF_CONFIRM: 'BOSS已确认',
        FINAL_CONFIRM: '人员已确认',
        UNKNOWN: '未知'
      }
      return statusMap[status]
    }
  },
  computed: {
    backCss() {
      let colorBack = this.color
      let color = this.$store.state.app.color
      let height = this.height
      let statusColor = this.checkedStatusKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      let contractTypeColor = this.checkedContractTypeKeys.length ? this.$store.state.app.color : 'rgba(0,0,0,0.65)'
      return {
        '--back-color': colorBack,
        '--theme-color': color,
        '--table-height': height,
        '--stage-color': statusColor,
        '--contract-color': contractTypeColor
      }
    }
  },
  watch: {
    checkedDeadlineKeys(val) {
      console.log('onCheck', val)
    },
    nodeList(val) {
      /* if(!document.getElementsByClassName('vxe-table--fixed-wrapper')[0]) return */
      if (val.length) {
        if (
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document
                .getElementsByClassName('vxe-table--main-wrapper')[0]
                .getElementsByClassName('vxe-table--header')[0]
                .getElementsByTagName('thead')[0].childNodes[1]
            )
        }
        document
          .getElementsByClassName('vxe-table--main-wrapper')[0]
          .getElementsByClassName('vxe-table--header')[0]
          .getElementsByTagName('thead')[0]
          .appendChild(val[val.length - 1])
      } else {
        if (
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0].childNodes.length > 1
        ) {
          document
            .getElementsByClassName('vxe-table--main-wrapper')[0]
            .getElementsByClassName('vxe-table--header')[0]
            .getElementsByTagName('thead')[0]
            .removeChild(
              document
                .getElementsByClassName('vxe-table--main-wrapper')[0]
                .getElementsByClassName('vxe-table--header')[0]
                .getElementsByTagName('thead')[0].childNodes[1]
            )
        }
      }
    },
    scrollList(val) {
      if (val.length) {
        this.height = 'calc(100vh - 355px)'
      } else {
        this.height = 'calc(100vh - 305px)'
      }
    }
  },
  methods: {
    moment,
    showTaskList(row) {
      sessionStorage.setItem('detailedList_data', JSON.stringify({ ...row, isRating: true }))
      window.open(`/system/detailedList/?&name=${row.nickName}-评级明细`)
      // this.$router.push({
      //   path: `/system/detailedList/?&name=${row.nickName}-评级明细`
      // })
    },
    copyNodeClick(e) {
      let node = document
        .getElementsByClassName('vxe-table--header-wrapper body--wrapper')[0]
        .getElementsByClassName('vxe-body--row')[0]
      let openArr = this.$refs.xTree.getTreeExpandRecords()
      if (
        openArr.findIndex(i => {
          return i._X_ROW_KEY == node.attributes[0].value
        }) > -1
      ) {
        console.log(this.tableData)
        this.tableData.forEach(item => {
          item.children.forEach(i => {
            if (i._X_ROW_KEY == node.attributes[0].value) {
              this.$refs.xTree.clearTreeExpandLoaded(
                openArr[
                  openArr.findIndex(i => {
                    return i._X_ROW_KEY == node.attributes[0].value
                  })
                ]
              )
              this.$refs.xTree.updateData()
            }
          })
        })
      }
    },
    handleScroll(e) {
      let openArr = this.$refs.xTree.getTreeExpandRecords()
      openArr = openArr.filter(item => item.isDepart)
      let copyNode = null
      Array.from(
        document.getElementsByClassName('vxe-table--main-wrapper')[0].getElementsByClassName('vxe-body--row')
      ).forEach(item => {
        if (
          openArr.findIndex(i => {
            return i._X_ROW_KEY == item.attributes[0].value
          }) > -1
        ) {
          if (item.getClientRects()[0].top < 160) {
            copyNode = item.cloneNode(true)
            if (copyNode.getElementsByClassName('ant-pagination mini')[0]) {
              this.moveNode = null
              let parent = copyNode.getElementsByClassName('ant-pagination mini')[0].parentNode
              parent.removeChild(copyNode.getElementsByClassName('ant-pagination mini')[0])
              parent.appendChild(item.getElementsByClassName('ant-pagination mini')[0])
              this.moveNode = item
            }
            copyNode.style.backgroundColor = '#fff'
            copyNode.addEventListener('click', this.copyNodeClick)
            if (
              this.nodeList.findIndex(n => {
                return n.attributes[0].value == copyNode.attributes[0].value
              }) == -1
            ) {
              this.scrollList.push(e.target.scrollTop)
              this.nodeList.push(copyNode)
            }
          }
        }
      })
      if (e.target.scrollTop < this.scrollList[this.scrollList.length - 1]) {
        this.scrollList.splice(this.scrollList.length - 1, 1)
        if (this.nodeList[this.nodeList.length - 1].getElementsByClassName('ant-pagination mini')[0]) {
          let pageNode = this.nodeList[this.nodeList.length - 1].getElementsByClassName('ant-pagination mini')[0]
          this.moveNode.childNodes[this.moveNode.childNodes.length - 1].childNodes[0].childNodes[0].appendChild(
            pageNode
          )
        }
        this.nodeList.splice(this.nodeList.length - 1, 1)
      }
      let copyNodeFixed = null
      Array.from(
        document.getElementsByClassName('vxe-table--main-wrapper')[0].getElementsByClassName('vxe-body--row')
      ).forEach(item => {
        if (
          openArr.findIndex(i => {
            return i._X_ROW_KEY == item.attributes[0].value
          }) > -1
        ) {
          if (item.getClientRects()[0].top < 160) {
            copyNodeFixed = item.cloneNode(true)
            if (copyNodeFixed.getElementsByClassName('ant-pagination mini')[0]) {
              this.moveNodeFixed = null
              this.moveNodeFixed = item
            }
            copyNodeFixed.style.backgroundColor = '#fff'
            copyNodeFixed.style.position = 'relative'
            copyNodeFixed.style.zIndex = '10000'
            copyNodeFixed.addEventListener('click', this.copyNodeClick)
            if (
              this.nodeListFixed.findIndex(n => {
                return n.attributes[0].value == copyNodeFixed.attributes[0].value
              }) == -1
            ) {
              this.scrollList.push(e.target.scrollTop)
              this.nodeListFixed.push(copyNodeFixed)
            }
          }
        }
      })
      if (e.target.scrollTop < this.scrollList[this.scrollList.length - 1]) {
        this.scrollList.splice(this.scrollList.length - 1, 1)
        this.nodeListFixed.splice(this.nodeListFixed.length - 1, 1)
      }
    },
    treeExpand(keys) {
      this.defaultOpenKeys = keys
    },
    async handleOk() {
      this.visible = false
      let saveArr = this.selectRows
        .filter(item => item.chapterSortAndStageName)
        .map(item => {
          return {
            taskId: item.taskId,
            ownerId: item.ownerId,
            id: item.taskId,
            chapterId: item.chapterId,
            stageId: item.stageId,
            productionId: item.productionId,
            settlementDate: item.settlementDate ? item.settlementDate + '-01' : null
          }
        })
      saveArr.forEach(item => {
        item.ownerId = this.ownerId
      })
      const res = await postAction('/tasks/updateTasks', saveArr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.ownerId = undefined
        this.getTableData()
        this.clearSelectKeys()
      } else {
        this.$message.error(res.msg)
      }
    },
    async listEidt(row) {
      let saveArr = [
        {
          taskId: row.taskId,
          ownerId: row.ownerId,
          id: row.taskId,
          chapterId: row.chapterId,
          stageId: row.stageId,
          productionId: row.productionId,
          settlementDate: row.settlementDate ? row.settlementDate + '-01' : null,
          deadline:
            row.deadline.length <= 17 && row.deadline.length > 10
              ? row.deadline + ':00'
              : row.deadline.length <= 10
              ? row.deadline + ' 23:59:59'
              : row.deadline
        }
      ]
      const res = await postAction('/tasks/updateTasks', saveArr)
      if (res.code == 200) {
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    handleCancel() {
      this.visible = false
      this.ownerId = undefined
    },
    treeSelect(type, selectedKeys, info) {
      console.log(selectedKeys, info)
      if (type == 'deadline') {
        this.checkedDeadlineKeys = selectedKeys
      } else if (type == 'settlementDate') {
        this.checkedSettlementDateKeys = selectedKeys
      } else if (type == 'status') {
        this.checkedStatusKeys = selectedKeys
      } else if (type == 'finishStatus') {
        this.checkedFinishStatusKeys = selectedKeys
      } else if (type == 'evalResult') {
        this.checkedEvalResultKeys = selectedKeys
      } else if (type == 'contractType') {
        this.checkedContractTypeKeys = selectedKeys
      }
      this.selectedTreeKeys = selectedKeys
      this.$refs.xTree.closeFilter()
      if (this.filters.indexOf(type) == -1) {
        this.filters.push(type)
        this.gexpr = this.filters.length
        this.filters.forEach((i, idx) => {
          this.gexpr = this.gexpr + '&' + idx
        })
      }
      this.getTableData()
    },
    handleUserSelectOn() {
      this.visible = true
    },
    handleEditSelectOn(isEditSelect) {
      this.selectRows
        .filter(item => item.chapterSortAndStageName)
        .forEach(item => {
          item.editable = isEditSelect
        })
    },
    async filterVisible(e) {
      let res = null
      this.filterLoading = true
      if (e.visible) {
        switch (e.property) {
          case 'status':
            let data = JSON.parse(JSON.stringify({ ...this.queryParmas }))
            for (let key in data) {
              if (!isNaN(Number(key.split('.')[0], 10)) && key.split('.')[1].split('-')[0] != 'ownerId') {
                delete data[key]
              }
              if (key.indexOf('group') > -1) {
                delete data[key]
              }
            }
            data.groupBy = 'status'
            res = await getAction('/kt/rating/beanSearcher', data)
            res.data = res.data.filter(item => item.status)
            this.filterLoading = false
            this.treeData = []
            let dateTree3 = []
            res.data?.forEach(item => {
              dateTree3.push({
                title: this.statusMap[item.status],
                value: item.status,
                key: item.status
              })
            })
            this.treeData = dateTree3
            return
          case 'contractType':
            let data2 = {}
            /*             for (let key in data) {
              if (!isNaN(Number(key.split('.')[0], 10)) && key.split('.')[1].split('-')[0] != 'ownerId') {
                delete data2[key]
              }
              if (key.indexOf('group') > -1) {
                delete data2[key]
              }
            } */
            data2.groupBy = 'contractType'
            res = await getAction('/kt/settle/beanSearcher', data2)
            res.data = res.data.filter(item => item.contractType)
            this.filterLoading = false
            this.treeData = []
            let dateTree = []
            res.data?.forEach(item => {
              dateTree.push({
                title: item.contractType,
                value: item.contractType,
                key: item.contractType
              })
            })
            this.treeData = dateTree
            return
        }
      }
    },
    deadlineChange(date, dateString) {
      this.deadline = dateString
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      this.$refs.xTree.updateData()
      return
    },
    compute(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText = menuText + '：' + this[methodName]([], index)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计' && i == menuIndex) {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    computeEval(methodName, index, menuIndex, menuText) {
      /* this.property='weekTaskCount' */
      this.methodName = methodName
      if (
        !this.propertys.some(item => {
          return item == index
        })
      ) {
        this.propertys.push(index)
      }
      this.$nextTick(() => {
        this.$refs.xTree.updateFooter() //手动更新表尾
        setTimeout(() => {
          this.$refs.xTree.setCheckboxRow(this.selectRows, true)
          Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
            .filter((item, index) => {
              return index != 0
            })
            .forEach((item, i) => {
              try {
                if (i == menuIndex) {
                  this.$refs['statistics-' + i][0].$el.innerText =
                    menuText + '：' + this[methodName]([], index, menuText)
                }
                if (this.$refs['statistics-' + i][0].$el.innerText != '统计') {
                  item.childNodes[0].childNodes[0].innerText = ''
                  item.childNodes[0].childNodes[0].style.opacity = '1'
                }
                item.childNodes[0].childNodes[0].appendChild(this.$refs['statistics-' + i][0].$el)
              } catch (err) {}
            })
        }, 0)
      })
    },
    sumNumEval(list, field, menuText) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      let type = ''
      if (menuText == '软伤') {
        type = 'SOFT'
      } else if (menuText == '硬伤') {
        type = 'HARD'
      } else if (menuText == '修改率') {
        type = 'kpiModifyRatio'
      } else if (menuText == '错翻') {
        type = 'ERROR'
      }
      console.log(list)
      let count = 0
      if (type == 'SOFT') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'SOFT') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'HARD') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'HARD') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'ERROR') {
        list.forEach(item => {
          item.evalResult.kpiValueErrors?.forEach(i => {
            if (i.type == 'ERROR') {
              count += i.count - 0
            }
          })
        })
      } else if (type == 'kpiModifyRatio') {
        list.forEach(item => {
          if (item.evalResult.kpiModifyRatio) {
            count = count - 0 + (item.evalResult.kpiModifyRatio - 0)
          }
        })
      }
      let num = count / list.length
      if (type == 'kpiModifyRatio') {
        return num.toFixed(2) + '%'
      }
      return num.toFixed(2) || 0
    },
    treeTablePageChange(page, pageSize, row) {
      row.page = page
      this.$refs.xTree.reloadTreeExpand(row)
    },
    meanNum(list, field) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      console.log(list, field)
      if (field == 'totalScore') {
        let count = 0
        list
          .filter(item => item.totalScore)
          .forEach(item => {
            count += Number(item.totalScore)
          })
        return count || 0
      } else if (field == 'factor') {
        let count = 0
        list
          .filter(item => item.factor)
          .forEach(item => {
            count += Number(item.factor)
          })
        return count.toFixed(2) || 0
      }
    },
    allNum(list, field) {
      return list.length
    },
    sumNum(list, field) {
      list = this.selectRows.filter(item => item.chapterSortAndStageName)
      if(!list.length) return 0
      if (field == 'totalScore') {
        let count = 0
        list
          .filter(item => item.totalScore)
          .forEach(item => {
            count += Number(item.totalScore)
          })
        let num = count / list.length
        return num.toFixed(2) || 0
      } else if (field == 'factor') {
        let count = 0
        list
          .filter(item => item.factor)
          .forEach(item => {
            count += Number(item.factor)
          })
        let num = count / list.length
        return num.toFixed(2) || 0
      }
    },
    checkChange(menuIndex) {
      this.copyColumns = this.columns.filter(item => {
        return item.checked
      })
      Array.from(document.getElementsByClassName('vxe-footer--row')[0].childNodes)
        .filter((item, index) => {
          return index != 0
        })
        .forEach((item, i) => {
          try {
            if (i == menuIndex) {
              if (this.columns[i].checked) {
                item.style.display = 'table-cell'
              } else {
                item.style.display = 'none'
              }
            }
          } catch (err) {}
        })
    },
    cellClickEvent(e) {
      console.log(e._columnIndex, e.row.editable)
      if (this.isEdit) return
      if (e.row.editable) return
      if (!e._columnIndex) return
      if (e.row.groupCount) {
        if (e.row.expend) {
          e.row.expend = false
        } else {
          e.row.expend = true
        }
        this.$refs.xTree.toggleTreeExpand(e.row)
        this.$refs.xTree.updateData()
        return
      }
      if (e.row.groupCount == 10) return
      if ((e._columnIndex && !this.isEdit && e.row.ratingOpened) || this.userInfo.id != e.row.userId) {
        this.$emit('open', e.row)
      } else if (e._columnIndex && !this.isEdit && !e.row.ratingOpened && this.userInfo.id == e.row.userId) {
        /*   getAction('/kt/rating/getMsg?ratingId=' + e.row.id).then(res => {
            if (res.data) {
              this.showAnnouncement({ anntId: res.data })
            } else {
              this.$message.error('请等待流程处理结束')
            }
          }) */
        console.log({ moment, 'e.row': e.row })
        this.$refs.ShowAnnouncement.detail({
          id: null,
          anntId: null,
          userId: e.row.userId,
          createBy: null,
          createTime: moment(new Date()).format('yyyy-MM-dd'),
          title: `【评级】${moment(new Date())
            .subtract(1, 'M')
            .format('yyyy年MM月')}评级刮刮乐开奖啦`,
          msgContent: `<p></p><p>\n</p><p>前程似锦的${e.row.nickName}：</p><p>\n</p><p></p><p>\n</p><p>上个月的评级结果出来啦，轻轻一刮，梦想开花~</p>`,
          sender: '漫小崔',
          priority: 'HIGH',
          readFlag: '0',
          sendTime: moment(new Date()).format('yyyy-MM-dd'),
          pageNo: null,
          pageSize: null,
          msgCategory: '2',
          busId: e.row.id,
          busType: null,
          openType: null,
          openPage: null,
          bizSource: null,
          msgAbstract: null,
          templateId: null,
          template: null,
          busObj: null,
          noReadUsers: null,
          scene: 'GRADE',
          scenes: null
        })
      }
      // this.$emit('open', e.row)
    },
    showAnnouncement(record) {
      getAction('/sys/sysAnnouncementSend/getMyAnnouncementSend?msgCategory=2&id=' + record.anntId).then(res => {
        if (res.success) {
          if (res.data.records.length) {
            this.$refs.ShowAnnouncement.detail(res.data.records[0])
          }
        }
      })
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (this.propertys.includes(column.property)) {
            return parseInt(this[this.methodName](data, column.property))
          }
          if (columnIndex == 0) {
            return '已选择：' + this.selectRows.filter(item => item.chapterSortAndStageName).length + '项'
          }
          return ''
        })
      ]
    },
    async getTableData() {
      let openArr = []
      if (this.$refs.xTree) {
        openArr = this.$refs.xTree.getTreeExpandRecords()
      }

      this.tableLoading = true
      this.queryParmas = {
        current: 1,
        size: 50,
        groupBy: 'month',
        orderBy: 'contractType:asc'
      }
      if (this.gexpr || this.gexpr === 0) {
        this.queryParmas.gexpr = this.gexpr
        this.filters.forEach((item, index) => {
          if (item == 'status') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'status') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.status' + '-op'] = 'eq'
          } else if (item == 'contractType') {
            this.checkedContractTypeKeys?.forEach((i, idx) => {
              if (item == 'contractType') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.contractType' + '-op'] = 'eq'
          }
        })
      }
      this.queryParamProp.userIds?.forEach((item, index) => {
        this.queryParmas[this.filters.length + '.userId' + '-' + index] = item
        this.queryParmas[this.filters.length + '.userId' + '-op'] = 'il'
      })
      if (this.queryParamProp.month) {
        this.queryParmas[this.filters.length + '.month' + '-0'] = this.queryParamProp.month
        this.queryParmas[this.filters.length + '.month' + '-op'] = 'eq'
      }
      const res = await getAction('/kt/rating/beanSearcher', this.queryParmas)
      try {
        if (res.code == 200) {
          res.data?.forEach(item => {
            item.name = item.time ? item.month + '（' + item.time + '）' : item.month
            item.hasChild = item.groupCount ? true : false
            item.productTotal = item.groupCount
            item.id = item.month
            item.isMonth = true
          })
          this.tableData = res.data
          this.$nextTick(() => {
            document
              .getElementsByClassName('vxe-table--body-wrapper body--wrapper')[0]
              .addEventListener('scroll', this.handleScroll)
          })
          this.tableLoading = false
          if (openArr.length) {
            //保留展开状态
            openArr.forEach(row => {
              if (!row.isDepart) {
                setTimeout(() => {
                  this.$refs.xTree.setTreeExpand(this.$refs.xTree.getRowById(row.id), true)
                }, 0)
              } else {
                setTimeout(() => {
                  this.$refs.xTree.setTreeExpand(this.$refs.xTree.getRowById(row.id), true)
                }, 500)
              }
            })
          }
        }
      } catch (err) {}
    },
    inputFilter(dataIndex) {
      this.filterChangeEvent({
        column: {
          property: dataIndex
        },
        $event: {
          target: {
            innerText: '筛选'
          }
        }
      })
      this.$refs.xTree.closeFilter()
    },
    async loadChildrenMethod({ row }) {
      // 异步加载子节点
      if (row.isMonth) {
        this.queryParmas = {
          current: 1,
          size: 50,
          groupBy: 'departName',
          orderBy: 'contractType:asc'
        }
        this.queryParmas.gexpr = 'group0&' + this.gexpr
        this.queryParmas['group0.month'] = row.month
        this.filters.forEach((item, index) => {
          if (item == 'status') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'status') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.stageName' + '-op'] = 'eq'
          } else if (item == 'contractType') {
            this.checkedContractTypeKeys?.forEach((i, idx) => {
              if (item == 'contractType') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.contractType' + '-op'] = 'eq'
          }
        })
        this.queryParamProp.userIds?.forEach((item, index) => {
          this.queryParmas[this.filters.length + '.userId' + '-' + index] = item
          this.queryParmas[this.filters.length + '.userId' + '-op'] = 'il'
        })
        const res = await getAction('/kt/rating/beanSearcher', this.queryParmas)
        try {
          if (res.code == 200) {
            res.data?.forEach((item, index) => {
              item.name = item.departName
              item.productTotal = item.groupCount
              item.page = 1
              item.hasChild = item.groupCount ? true : false
              item.id = row.month + item.departId
              item.month = row.month
              item.isDepart = true
            })
            this.clearSelectKeys()
          }
          return res.data
        } catch (err) {}
      } else if (row.isDepart) {
        if (this.queryParmas.groupBy) delete this.queryParmas.groupBy
        this.queryParmas.gexpr = 'group0&group1&' + this.gexpr
        this.queryParmas['group0.month'] = row.month
        this.queryParmas['group1.departName'] = row.departName
        this.filters.forEach((item, index) => {
          if (item == 'status') {
            this.checkedStatusKeys?.forEach((i, idx) => {
              if (item == 'status') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.status' + '-op'] = 'eq'
          } else if (item == 'contractType') {
            this.checkedContractTypeKeys?.forEach((i, idx) => {
              if (item == 'contractType') {
                this.queryParmas[index + '.' + item + '-' + idx] = i
              }
            })
            this.queryParmas[index + '.contractType' + '-op'] = 'eq'
          }
        })
        this.queryParmas.current = row.page
        this.queryParamProp.userIds?.forEach((item, index) => {
          this.queryParmas[this.filters.length + '.userId' + '-' + index] = item
          this.queryParmas[this.filters.length + '.userId' + '-op'] = 'il'
        })
        const res = await getAction('/kt/rating/beanSearcher', this.queryParmas)
        try {
          if (res.code == 200) {
            res.data.dataList?.forEach(item => {
              item.id = item.id
              item.editable = false
            })
            this.clearSelectKeys()
            return res.data.dataList
          }
        } catch (err) {}
      }
    },
    async filterChangeEvent({ column, $event, values, filters }) {
      console.log(column, $event, values, filters)
      if ($event.target.innerText == '筛选') {
        if (this.filters.indexOf(column.property) == -1) {
          this.filters.push(column.property)
          this.gexpr = this.filters.length
          this.filters.forEach((i, idx) => {
            this.gexpr = this.gexpr + '&' + idx
          })
        }
        this.getTableData()
      } else {
        if (column.property == 'deadline') {
          this.checkedDeadlineKeys = []
        } else if (column.property == 'settlementDate') {
          this.checkedSettlementDateKeys = []
        } else if (column.property == 'status') {
          this.checkedStatusKeys = []
        } else if (column.property == 'name') {
          this.productName = ''
        } else if (column.property == 'finishStatus') {
          this.checkedFinishStatusKeys = []
        } else if (column.property == 'totalScore') {
          this.minTotalScore = null
          this.maxTotalScore = null
        } else if (column.property == 'evalResult') {
          this.checkedEvalResultKeys = []
        } else if (column.property == 'contractType') {
          this.checkedContractTypeKeys = []
        }
        if (this.filters.indexOf(column.property) > -1) {
          this.filters.splice(this.filters.indexOf(column.property), 1)
          this.gexpr = this.filters.length
          this.filters.forEach((i, idx) => {
            this.gexpr = this.gexpr + '&' + idx
          })
        }
        this.getTableData()
      }
    },
    clearSelectKeys() {
      this.$emit('setRowkeys', [])
      this.$refs.xTree.clearCheckboxRow()
      this.selectRows = []
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    selectChangeEvent() {
      const records = this.$refs.xTree.getCheckboxRecords()
      this.$emit('setRowkeys', records)
      this.selectRows = records
      this.$refs.xTree.updateFooter() //手动更新表尾
    }
  }
}
</script>

<style lang="less" scoped>
/* /deep/ .vxe-table--fixed-wrapper .vxe-table--footer-wrapper{
    display: none !important;
  } */
/deep/ .vxe-table--render-default .vxe-table--fixed-right-wrapper {
  box-shadow: none !important;
}

/deep/ .vxe-filter--btn {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 16px;
  position: relative;
  top: -2px;
}

.tree-page {
  position: absolute;
  width: 190px;
  left: -202px;
  top: 12px;
}

/deep/ .filter-contractType-btn {
  color: var(--stage-color) !important;
}

/deep/ .filter-name-btn {
  color: var(--contract-color) !important;
}

/deep/ .vxe-table--body-wrapper {
  max-height: var(--table-height) !important;
}

/* /deep/ .vxe-table--fixed-left-wrapper {
    .vxe-table--body-wrapper{
      max-height: var(--table-height) !important;
    }
  } */
.review-product:hover {
  color: var(--theme-color);
  cursor: pointer;
}

.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;

  &:hover {
    background: var(--back-color);
  }
}

.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;

  i {
    &:hover {
      cursor: pointer;
    }
  }
}

.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}

.statistics-menu-list {
  opacity: 0;
  height: 0;
}

/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        width: 120px;
      }
    }
  }
}

/deep/ .vxe-cell--sort {
  font-size:12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}

/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}

/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;

    .vxe-cell--title {
      flex: 1;
    }
  }
}

.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;

  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}

/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}

/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}

/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}

/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}

/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}

/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}

/deep/ .is--checked {
  color: var(--theme-color) !important;
}

/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;

  button {
    &:hover {
      color: var(--theme-color);
    }
  }
}

/* /deep/ .fixed-right--wrapper .col_3{
    opacity: 0 !important;
  } */
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}

/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}

/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}

/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}

/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}

.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}

/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}

/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}

/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
</style>
