var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.backCss },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.tableLoading } },
        [
          _c(
            "a-dropdown",
            {
              attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
              model: {
                value: _vm.rightVisible,
                callback: function ($$v) {
                  _vm.rightVisible = $$v
                },
                expression: "rightVisible",
              },
            },
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                _vm._l(_vm.columns, function (item, index) {
                  return _c(
                    "a-menu-item",
                    { key: item.dataIndex },
                    [
                      _c(
                        "a-checkbox",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.checkChange(index)
                            },
                          },
                          model: {
                            value: item.checked,
                            callback: function ($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "vxe-table",
                {
                  ref: "xTree",
                  attrs: {
                    border: "none",
                    "row-config": {
                      isCurrent: true,
                      isHover: true,
                      keyField: "id",
                    },
                    "column-config": { resizable: true },
                    "tree-config": _vm.treeConfig,
                    "filter-config": {
                      remote: true,
                      iconNone: "iconfont icon-shaixuan icon",
                      iconMatch:
                        "iconfont icon-shaixuan icon filter-product-btn",
                    },
                    "expand-config": { reserve: true },
                    data: _vm.tableData,
                  },
                  on: {
                    "checkbox-change": _vm.selectChangeEvent,
                    "checkbox-all": _vm.selectChangeEvent,
                    "cell-click": _vm.cellClickEvent,
                    "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                    "filter-visible": _vm.filterVisible,
                    "filter-change": _vm.filterChangeEvent,
                  },
                },
                [
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      fixed: "left",
                      type: "checkbox",
                      minWidth: "50",
                      "show-overflow": "",
                    },
                  }),
                  _vm._l(_vm.copyColumns, function (record) {
                    return _c("vxe-column", {
                      key: record.dataIndex,
                      attrs: {
                        field: record.dataIndex,
                        title: record.title,
                        minWidth: record.width,
                        "tree-node": record.dataIndex == "name",
                        "show-overflow": "",
                        filters:
                          record.dataIndex == "status" ||
                          record.dataIndex == "contractType"
                            ? []
                            : null,
                      },
                      scopedSlots: _vm._u(
                        [
                          record.dataIndex == "status"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.isDepart && row.productTotal > 50
                                      ? _c(
                                          "div",
                                          { staticStyle: { width: "200px" } },
                                          [
                                            _c("a-pagination", {
                                              attrs: {
                                                size: "small",
                                                showLessItems: true,
                                                defaultPageSize: 50,
                                                "default-current": 1,
                                                total: row.productTotal - 0,
                                              },
                                              on: {
                                                change: (page, pageSize) => {
                                                  _vm.treeTablePageChange(
                                                    page,
                                                    pageSize,
                                                    row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("statusFilter")(
                                                  row.status
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "name"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(row.name) +
                                          _vm._s(
                                            row.isDepart && row.productTotal
                                              ? "（" + row.productTotal + "）"
                                              : ""
                                          )
                                      ),
                                    ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "level"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _vm.isEdit && !row.hasChild
                                      ? _c(
                                          "a-select",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                            model: {
                                              value: row.level,
                                              callback: function ($$v) {
                                                _vm.$set(row, "level", $$v)
                                              },
                                              expression: "row.level",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "专家" } },
                                              [_vm._v("专家")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "高级" } },
                                              [_vm._v("高级")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "中级" } },
                                              [_vm._v("中级")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "低级" } },
                                              [_vm._v("低级")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "新人" } },
                                              [_vm._v("新人")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              row.ratingOpened ||
                                                _vm.userInfo.id != row.userId
                                                ? row.level
                                                : ""
                                            )
                                          ),
                                        ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "duration"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.ratingOpened ||
                                            _vm.userInfo.id != row.userId
                                            ? row.duration != null
                                              ? row.duration > 0
                                                ? row.duration + "个月"
                                                : "小于1个月"
                                              : ""
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "taskCount"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.ratingOpened ||
                                            _vm.userInfo.id != row.userId
                                            ? row.taskCount
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              }
                            : record.dataIndex == "action"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    !row.hasChild
                                      ? _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.showTaskList(row)
                                              },
                                            },
                                          },
                                          [_vm._v("查看明细")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              }
                            : record.dataIndex == "averageScore"
                            ? {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.ratingOpened ||
                                            _vm.userInfo.id != row.userId
                                            ? row.averageScore
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              }
                            : null,
                          record.dataIndex == "status"
                            ? {
                                key: "filter",
                                fn: function ({ $panel, column }) {
                                  return [
                                    _c(
                                      "a-spin",
                                      {
                                        staticStyle: {
                                          "padding-left": "10px",
                                          "padding-right": "20px",
                                          "min-height": "30px",
                                          "max-height": "200px",
                                          "overflow-y": "auto",
                                        },
                                        attrs: { spinning: _vm.filterLoading },
                                      },
                                      [
                                        _c("a-tree", {
                                          attrs: {
                                            checkable: "",
                                            "tree-data": _vm.treeData,
                                          },
                                          on: {
                                            select: (selectedKeys, info) => {
                                              _vm.treeSelect(
                                                record.dataIndex,
                                                selectedKeys,
                                                info
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkedStatusKeys,
                                            callback: function ($$v) {
                                              _vm.checkedStatusKeys = $$v
                                            },
                                            expression: "checkedStatusKeys",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              }
                            : record.dataIndex == "contractType"
                            ? {
                                key: "filter",
                                fn: function ({ $panel, column }) {
                                  return [
                                    _c(
                                      "a-spin",
                                      {
                                        staticStyle: {
                                          "padding-left": "10px",
                                          "padding-right": "20px",
                                          "min-height": "30px",
                                          "max-height": "200px",
                                          "overflow-y": "auto",
                                        },
                                        attrs: { spinning: _vm.filterLoading },
                                      },
                                      [
                                        _c("a-tree", {
                                          attrs: {
                                            checkable: "",
                                            "tree-data": _vm.treeData,
                                          },
                                          on: {
                                            select: (selectedKeys, info) => {
                                              _vm.treeSelect(
                                                record.dataIndex,
                                                selectedKeys,
                                                info
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkedContractTypeKeys,
                                            callback: function ($$v) {
                                              _vm.checkedContractTypeKeys = $$v
                                            },
                                            expression:
                                              "checkedContractTypeKeys",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-announcement", {
        ref: "ShowAnnouncement",
        attrs: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }